import { ResponseData, ResponseError, axiosInstance, Request } from '@/helpers'
const moduleName = 'topic'
export const GetThread = () =>{
  const req = Request();
  return axiosInstance.get(`/${moduleName}`,req).then(ResponseData).catch(ResponseError)
}
export const GetReply = ({id}) =>{
  const req = Request();
  return axiosInstance.get(`/${moduleName}/thread/${id}`,req).then(ResponseData).catch(ResponseError)
}
export const NewThread = ({Title, Content}) =>{
  const req = Request();
  return axiosInstance.post(`/${moduleName}/add`,{Title, Content},req).then(ResponseData).catch(ResponseError)
}
export const NewReply = ({id, Content}) =>{
  const req = Request();
  return axiosInstance.post(`/${moduleName}/thread/${id}`,{Content},req).then(ResponseData).catch(ResponseError)
}
export const UpdateReply = ({id, Content}) =>{
  const req = Request();
  return axiosInstance.post(`/${moduleName}/reply/${id}`,{Content},req).then(ResponseData).catch(ResponseError)
}
export const DeleteThread = ({id}) =>{
  const req = Request();
  return axiosInstance.get(`/${moduleName}/delete/${id}`,req).then(ResponseData).catch(ResponseError)
}
export default {
  GetThread,
  NewThread,
  GetReply,
  NewReply,
  UpdateReply,
  DeleteThread,
}